<template>
  <div class="main-container">
    <h2>{{ "Rumble Rivals Daily Technical Report - " + dateText  }}</h2>
    <div
      id="load-selector"
      v-if="
        loadingState.isWeeklyChartLoaded &&
        loadingState.isDailyChartLoaded &&
        loadingState.isLogsLoaded
      "
    ></div>
    <div class="table-section">
      <BasicLogTable
        title="Module Based Error Logs"
        :logs="moduleLogs"
        :columns="['source', 'user_cnt', 'avg', 'count', 'weekly_per_ccu', 'count_per_ccu']"
				:column_names="['Source', 'User Count', 'Avg (7days)', 'Count', 'Avg (7 days) Per Ccu', 'Count Per Ccu']"
        :trendColumns="['weekly_per_ccu', 'count_per_ccu']"
      />
      <BasicLogTable
        title="User Based Error Logs"
        :logs="userLogs"
        :columns="['source', '', '', 'count']"
				:column_names="['Source', '', '', 'Count']"
      />
    </div>
    <div class="table-section">
      <BasicLogTable
        title="In App Purchase Issues By User Id"
        :logs="purchaseLogs.length === 0 ? [{ source: 'No purchase related issues' }] : purchaseLogs"
        :columns="['source', '', '', 'count']"
        :column_names="['Source', '', '', 'Count']"
      />
    </div>
    <div class="table-section">
      <div class="chart-container-inner">
        <h4>Daily CCU</h4>
        <canvas id="ccu-chart"></canvas>
      </div>
      <div class="chart-container-inner">
        <h4>Weekly CCU</h4>
        <canvas id="ccu-chart-weekly"></canvas>
      </div>
    </div>
    <div class="table-section">
      <div class="chart-container-inner">
        <iframe
          src="https://app.datadoghq.com/graph/embed?token=3cdc017cd24cee5da06e6b5785b37c331fee29454ec56fb62cd4731ace793cd2&height=400&width=800&legend=true"
          width="800"
          height="400"
          frameborder="0"
        ></iframe>
      </div>
      <div class="chart-container-inner">
        <iframe
          src="https://app.datadoghq.com/graph/embed?token=ea9b787614597333b19c24f14bf9d97be3e23a3eabbc814f8277b3c2402ce00a&height=400&width=800&legend=true"
          width="800"
          height="400"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import BasicLogTable from "./components/BasicLogTable.vue";
import moment from "moment";
import Chart from "chart.js";

export default {
  name: "DailyReport",
  components: {
    BasicLogTable,
  },
  setup() {
    const store = useStore();

    const moduleLogs = ref([]);
    const userLogs = ref([]);
    const purchaseLogs = ref([])

		const dateText = ref("")

    let chart;
    let weeklyChart;
    let dailyCcu = 1;
    let weeklyCcu = 1;

    const loadingState = reactive({
      isWeeklyChartLoaded: false,
      isDailyChartLoaded: false,
      isLogsLoaded: false,
    });

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
			dateText.value = moment().subtract(1, 'days')
				.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('(MMMM Do YYYY)');
      setTimeout(() => {
        getCcuChart();
        getLogsReport();
      }, 2000);
    });

    const getCcuChart = () => {
      let reportStartDate = moment()
        .subtract(1, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      const reportEndDate = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();

      axios
        .post("/api/monitor/ccu-chart", {
          max_date: Math.floor(reportEndDate / 1000),
          min_date: Math.floor(reportStartDate / 1000),
          granularity: 5,
        })
        .then((resp) => {
          loadingState.isDailyChartLoaded = true;
          if (resp.status === 200) {
            if(resp.data.avgCcu)
              dailyCcu = resp.data.avgCcu;
            renderChart(resp.data.ccuChartData, chart, "ccu-chart", "rgba(156,73,93,.5)");
          }
        });

      reportStartDate = moment()
        .subtract(7, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();

      axios
        .post("/api/monitor/ccu-chart", {
          max_date: Math.floor(reportEndDate / 1000),
          min_date: Math.floor(reportStartDate / 1000),
          granularity: 60,
        })
        .then((resp) => {
          loadingState.isWeeklyChartLoaded = true;
          if (resp.status === 200) {
            if(resp.data.avgCcu)
              weeklyCcu = resp.data.avgCcu;
            renderChart(
              resp.data.ccuChartData,
              weeklyChart,
              "ccu-chart-weekly",
              "rgba(73,73,150,.5)"
            );
          }
        });
    };

    const getLogsReport = () => {

      axios
        .post("/api/monitor/logs-report", {})
        .then((result) => {
          loadingState.isLogsLoaded = true;
          if (result.status === 200) {
            moduleLogs.value = result.data.module_logs
              .map((x) => {
                return {
                  count: x.count,
                  user_cnt: x.user_cnt,
                  source:
                    x.source.length > 100
                      ? x.source.substring(0, 100)
                      : x.source,
                  avg: x.avg,
                  weekly_per_ccu: (x.avg/weeklyCcu).toFixed(2), 
                  count_per_ccu: (x.count/dailyCcu).toFixed(2), 
                };
              })
              .slice(0, 20);
            moduleLogs.value.push({
              source: "RR Certificate Issue",
              user_cnt: result.data.certificate_result[0].cnt,
              count: result.data.certificate_result[0].cnt,
              avg: result.data.average_certificate_result[0].avg,
              weekly_per_ccu: (result.data.average_certificate_result[0].avg/weeklyCcu).toFixed(2), 
              count_per_ccu: (result.data.certificate_result[0].cnt/dailyCcu).toFixed(2),
            });
            userLogs.value = result.data.user_logs.slice(0, 20);
            purchaseLogs.value = result.data.purchase_issues || []
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const renderChart = (data, chart, element, color) => {
      if (chart !== undefined) {
        udpateChartValues(data, chart);
      }
      const ctx = document.getElementById(element).getContext("2d");
      chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.map((x) => {
            return moment(x.time).format("DD MMMM HH:mm");
          }),
          datasets: [
            {
              label: "ccu",
              data: data.map((x) => x.ccu),
              backgroundColor: color,
              borderColor: color,
              borderWidth: 3,
              yAxisId: "default-y",
              xAxisId: "default-x",
            },
          ],
        },
        options: {
          responsive: true,
          aspectRatio: 2,
          scales: {
            yAxes: [
              {
                id: "default-y",
                type: "linear",
                ticks: { min: 0 },
              },
            ],
            xAxes: [
              {
                id: "default-x",
                ticks: { maxTicksLimit: 10 },
              },
            ],
          },
        },
      });
    };

    const udpateChartValues = (data, chart) => {
      chart.data.labels = data.map((x) => {
        return moment(x.time).format("DD/MM/YYYY HH:mm");
      });
      chart.data.datasets[0].data = data.map((x) => x.ccu);
      chart.update();
    };

    return {
      moduleLogs,
      userLogs,
      loadingState,
			dateText,
      purchaseLogs
    };
  },
};
</script>

<style scoped>
canvas {
  width: 800px !important;
  height: 400px !important;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 50px;
}

.chart-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  flex-direction: column;
}
</style>